import type { Intervention, Contact } from '@willig/types/api'
import type { ParsedFullInvoicesIntervention } from 'src/adapters/antiCorruptionLayerProxy'
import type { EntriesStatus } from 'src/ressources/intervention/interventionFormComponents/OtherRelationsActions'

import type { InvoiceErrorCodes } from '../../page/Invoice/types'

export type InterventionCreate = Omit<
  Intervention,
  'status' | 'need_invoice' | 'fixed_slot'
> & {
  otherEntries?: EntriesStatus
}

export type InterventionDetail = {
  invoice: ParsedFullInvoicesIntervention['invoice']
  intervention_report_id?: string
  devis: ParsedFullInvoicesIntervention['devis']
  devis_complementaire: ParsedFullInvoicesIntervention['devis_complementaire']
  payment: ParsedFullInvoicesIntervention['payment']
  certificat: ParsedFullInvoicesIntervention['certificat']
  bon_intervention: ParsedFullInvoicesIntervention['bon_intervention']
  etat_des_lieux: ParsedFullInvoicesIntervention['etat_des_lieux']
  prix_total: ParsedFullInvoicesIntervention['prix_total']
  sweeper: string
  installations: {
    detail: string
    prestations: string
  }[]
  sinao_id?: string
  sinao_invoice_status?: SinaoStatusEnum
  sinao_invoice_reference?: string
}

export enum InterventionStatusEnum {
  done = 2,
}

export type FullRaf = {
  id: string
  adresse_rue?: string
  adresse_code_postal?: string
  adresse_ville?: string
  adresse_zone?: number
  adresse_cote?: string
  next_intervention_date?: string // date-time
  contacts?: string // JSON
  anomalies?: number
  prix?: string
  hasintervention?: '0' | '1'
  last_intervention_date?: string // date-time
}

export type ExtendedFullRaf = Omit<FullRaf, 'contacts' | 'prix'> & {
  contacts: ExtendedContact[]
  prix?: number
}

export type ExtendedContact = {
  accept_sms: 0 | 1
  accept_email: 0 | 1 | null
} & Omit<Contact, 'accept_sms' | 'accept_email'>

type line = {
  count: number
  catalog_id: number
  catalog_price: number
}
type detail = {
  id: string
  checked: boolean
}

type installation = {
  id: string
  lines: line[]
  detail: detail[]
}

export type DailyInvoices = {
  id: string
  date_intervention?: string
  sinao_invoice_reference?: string
  pro_id?: string
  zoho_id_payeur: string
  sinao_invoice_status?: SinaoStatusEnum
  infos_contacts?: {
    contact_id: string
    zoho_id?: string
    raison_sociale?: string
    nom?: string
    prenom?: string
  }
  sinao_id?: string
  facturation_adresse?: {
    adresse_id: string
    zoho_id: string
    rue: string
    code_postal: string
    ville: string
  }
  adresses_interventions?: {
    adresse_id: string
    zoho_id: string
    rue: string
    code_postal: string
    ville: string
  }
  intervention_id: string
  intervention_report_id?: string
  price_ttc: string
  type_paiement: string
  invoice: {
    tva: number
    divers: string[]
    installations: installation[]
    price_edited: number
    price_computed: number
    intervention_id: string
    vatFreeTotalPrice?: number
    vatValue?: number
    vatAmount?: number
    payedPrice?: number
    vatFreeDiscount?: number
    totalCatalogPrice?: number
  }
  paiement: {
    paiement_cash: boolean
    paiement_check: boolean
    paiement_transfert: boolean
  }
  ramoneur: string
  num_commande?: string
  errors?: InvoiceErrorCodes[]
}

export enum SinaoStatusEnum {
  'notBilled' = 'notBilled', // non facturé (valeur locale)
  'draft' = 'draft',
  'final' = 'final',
  'paid' = 'paid',
  'billed' = 'billed', // envoyé en facturation (valeur locale)
}
