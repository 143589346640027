import Dialog from '@material-ui/core/Dialog'

import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import { makeStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import type { ContactsAdress } from '@willig/types/api'
import { EnumContactsAdressType } from '@willig/types/api'
import { isEmpty } from 'rambda'
import { useState } from 'react'
import { Button, useCreate, useNotify, useUpdateMany } from 'react-admin'
import { SelectInput } from 'src/components/SelectInput'
import { TypesenseInput } from 'src/components/Typesense/TypesenseInput'
import { convertEnumAsOptionWithValue } from 'src/libs/enumAsOptions'
import type { EntriesStatus } from 'src/ressources/intervention/interventionFormComponents/OtherRelationsActions'
import { OtherRelationActions } from 'src/ressources/intervention/interventionFormComponents/OtherRelationsActions'

const useStyles = makeStyles(() => {
  return {
    dialogContent: {
      backgroundColor: 'transparent',
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
      maxWidth: '800px',
    },
    dialogCard: {
      overflow: 'visible',
    },
  }
})

type AddRelationShipProps = {
  adresse_id: string
  refetch: () => void
}

export function AddRelationshipModal(props: AddRelationShipProps) {
  const { adresse_id, refetch } = props
  const [modalState, toggleModal] = useState(false)
  const [contactId, setContactId] = useState<string | undefined>(undefined)
  const [otherEntriesState, setOtherEntriesState] = useState<EntriesStatus>({}) // other entries in contactAdresse for resident contact and billing contact
  const [relationshipType, setRelationship] = useState<
    EnumContactsAdressType | undefined
  >()
  const [is_payor, setIs_payor] = useState<boolean>(false)
  const classes = useStyles()
  const notify = useNotify()
  const handleClose = () => {
    toggleModal(false)
  }
  const handleRelationshipChange = (value: EnumContactsAdressType) => {
    setRelationship(value)
  }
  const handleContactIdChange = (value: string) => {
    setContactId(value)
  }
  const handleIsPayorToggle = () => {
    setIs_payor((state) => {
      return !state
    })
  }
  const [create] = useCreate<ContactsAdress>()
  const [updateMany] = useUpdateMany()
  const createRelationship = () => {
    if (Object.values(otherEntriesState).some((e) => e === 0)) {
      notify(
        'Il manque le choix pour une autre relation entre Adresse et Contact',
        'error',
      )
      return
    }
    if (!contactId) {
      notify('Il manque le contact', 'error')
      return
    }
    if (!relationshipType) {
      notify('Il manque la relation', 'error')
      return
    }
    const datas = {
      adresse_id: adresse_id,
      contact_id: contactId,
      type: relationshipType,
      is_payor: is_payor,
      deleted: false, // important
    }
    create('ContactsAdress', datas, {
      onSuccess() {
        if (isEmpty(otherEntriesState)) {
          toggleModal(false)
          refetch()
        }
        const ContactAdresseToDelete = Object.entries(otherEntriesState)
          .filter(([, value]) => value === 2)
          .map((el) => el[0])
        updateMany(
          'ContactsAdress',
          ContactAdresseToDelete,
          { deleted: 1 },
          {
            onSuccess: () => {
              toggleModal(false)
              refetch()
            },
            onFailure: () => {
              notify('Une erreur est survenue', 'error')
              refetch()
            },
          },
        )
      },
    })
  }
  return (
    <>
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          toggleModal(true)
          setOtherEntriesState(() => {
            return {}
          })
          setContactId(undefined)
        }}
      >
        <>Ajouter une relation </>
      </Button>
      <Dialog
        aria-label={'Créer une relation'}
        open={modalState}
        onClose={handleClose}
      >
        <DialogTitle>{'Créer une relation'}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <TypesenseInput
            indexName="contacts"
            inputLabel="Rechercher un contact"
            selectDataCallback={handleContactIdChange}
          />
          <SelectInput<EnumContactsAdressType>
            label={'Relation'}
            choices={
              convertEnumAsOptionWithValue(EnumContactsAdressType) as any
            }
            onChange={handleRelationshipChange}
          />
          <FormControlLabel
            control={
              <Switch
                checked={is_payor}
                onChange={handleIsPayorToggle}
                name="isPayor"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label="Payeur"
          />

          {contactId && (
            <OtherRelationActions
              adresse_id={adresse_id}
              contact_id={contactId || ''}
              otherEntriesState={otherEntriesState}
              setOtherEntriesState={setOtherEntriesState}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={createRelationship}>
            <>Enregistrer</>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
